import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import AdminPulseTemplateStore from 'app/stores/AdminPulseTemplateStore';

import { ModelList } from './ModelList';
import PulseStatementModel from './PulseStatementModel';

export class PulseTemplateModel extends Model {
  static _store: AdminPulseTemplateStore;

  @observable id: number;
  @observable name: string;
  @observable lookup_tag: string;
  @observable order: number;
  @observable scope: string;
  @observable is_default?: boolean;
  @observable type: string;
  @observable statements = new ModelList<PulseStatementModel>(PulseStatementModel);
  @observable statement_templates = new ModelList<PulseStatementModel>(PulseStatementModel);

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PulseTemplateModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as PulseTemplateModel;
  }

  static get(id) {
    return this._get(id) as PulseTemplateModel;
  }
}

export default PulseTemplateModel;
