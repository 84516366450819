import React from 'react';

import { Checkbox, Skeleton } from 'antd';
import cx from 'classnames';

import './SelectableTable.scss';

import { useCallbackIfVisible } from 'app/hooks/useCallbackIfVisible';

import Spinner from '../Spinner';

export interface Row {
  id?: string;
  isSelected: boolean;
  columns: any[];
  isDisabled?: boolean;
}

export interface SelectableTableProps {
  id?: string;
  headers: string[];
  rows: Row[];
  disabled?: boolean;
  disableSelectAll?: boolean;
  isLoadingMore?: boolean;

  onRowSelect: (row: any) => void;
  onSelectAll?: (checked: boolean) => void;
  onLoadMore?: () => void;
}

const allRowsSelected = (rows: Row[]) => {
  return rows.length > 0 && rows.filter(({ isSelected }) => !isSelected).length == 0;
};

export const SelectableTable: React.FC<SelectableTableProps> = ({
  id,
  headers,
  rows,
  disabled,
  disableSelectAll,
  isLoadingMore,
  onSelectAll,
  onRowSelect,
  onLoadMore,
}) => {
  const refCallback = useCallbackIfVisible(onLoadMore);

  return (
    <div className="selectable-table" id={id}>
      <table>
        <thead>
          <tr>
            <th className="table-row-checkbox">
              {disableSelectAll ?? (
                <Checkbox
                  data-testid="select-all"
                  onChange={(event) => onSelectAll(event.target.checked)}
                  defaultChecked={allRowsSelected(rows)}
                  disabled={disabled}
                />
              )}
            </th>

            {headers.map((header, i) => (
              <th className={cx('table-column-name', { muted: disabled })} key={`header-${i}`}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="table-scroll-body">
          {rows.map((row, i) => (
            <tr ref={rows.length === i + 1 ? refCallback : null} key={`row-${row.id}`} id={row.id}>
              <td className="table-row-checkbox">
                <Checkbox
                  data-testid={`select-row-${i}`}
                  checked={row.isSelected}
                  onChange={() => onRowSelect(row)}
                  disabled={disabled || row.isDisabled}
                  id={`select-row-${i}`}
                />
              </td>
              {row.columns.map((column, i) => (
                <td className={cx('table-column-val', { muted: disabled })} key={`column-${i}`}>
                  {column}
                </td>
              ))}
            </tr>
          ))}
          {isLoadingMore && (
            <tr key="loadingMore" className="d-flex align-items-center">
              <td className="table-row-checkbox">
                <Spinner isLoading={true} />
              </td>
              {headers.map((header, i) => (
                <td key={`loading-${i}`} className="table-column-val">
                  <Skeleton.Input active></Skeleton.Input>
                </td>
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SelectableTable;
